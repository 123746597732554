<template>
  <div class="page-main-box">
    <!-- 表单 -->
    <div class="mt-40 d-flex justify-center">
      <Form
        ref="formRef"
        :model="formState"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        :rules="rules"
        class="form-box"
      >
        <!-- 手机号 -->
        <FormItem label="手机号" name="phone">
          <a-input
            v-model:value="$store.state.user.userInfo.mobile"
            style="width:320px;"
            readonly
          />
        </FormItem>
        <!-- 手机验证码 -->
        <FormItem label="手机验证码" name="code">
          <a-input v-model:value="formState.code" style="width:320px;" />
          <a-button
            class="code-text fz-12"
            type="link"
            :class="{ code: codeDisabled }"
            :disabled="codeDisabled"
            @click="handleGetCode"
          >
            {{ codeText }}
          </a-button>
        </FormItem>
        <!-- 新密码 -->
        <FormItem label="新密码" name="password">
          <a-input-password
            v-model:value="formState.password"
            style="width:320px;"
          />
          <a-tooltip class="tip" placement="top">
            <template #title>
              <p style="width:208px;">
                密码要求6位及以上，至少包含1个 数字和1个字母，字母区分大小写，
                支持特殊字符，不支持空格
              </p>
            </template>
            <Icon name="iconanniuneitishiicon" height="16" width="16" />
          </a-tooltip>
        </FormItem>
        <!-- 确认新密码 -->
        <FormItem label="确认新密码" name="repeat_password">
          <a-input-password
            v-model:value="formState.repeat_password"
            style="width:320px;"
          />
        </FormItem>

        <FormItem class="mt-64" :wrapper-col="{ span: 14, offset: 8 }">
          <a-button @click="handleRouteBack">取消</a-button>
          <a-button
            class="ml-16"
            type="primary"
            @click="handleSubmit"
            :loading="isLoading"
            >保存</a-button
          >
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import { useSendMessageApi, useResetPasswordApi } from "@/apis/user";
import $await from "@/utils/await";
import { getInvoiceRules } from "./contants/utils";
import { useStore } from "vuex";

export default {
  name: "ResetPassword",
  components: { Form, FormItem: Form.Item },
  setup() {
    const store = useStore();

    const formState = ref({
      code: "",
      password: "",
      repeat_password: ""
    });

    // 验证码倒计时状态
    const countStatus = ref(false);
    // 倒计时数字
    const countTime = ref(60);
    // 验证码按钮文案
    const codeText = ref("获取验证码");
    // 验证码按钮是否是不可点击状态
    const codeDisabled = ref(false);

    // 获取验证码
    const handleGetCode = async () => {
      const [, code] = await $await(
        useSendMessageApi({
          mobile: store.state.user.userInfo.mobile,
          message_type: "RESET_PWD"
        })
      );
      // let code = "验证码发送成功";
      if (code === "验证码发送成功") {
        countStatus.value = true;
        codeDisabled.value = true;
        let interval = setInterval(() => {
          if (countTime.value <= 1) {
            countStatus.value = false;
            clearInterval(interval);
            codeDisabled.value = false;
            countTime.value = 60;
            codeText.value = "获取验证码";
          } else {
            countTime.value--;
            codeText.value = `再次获取(${countTime.value}s)`;
          }
        }, 1000);
      }
    };

    const formRef = ref();

    let validatePass = async (rule, value) => {
      if (value === "") {
        return Promise.reject("请输入新密码!");
      } else {
        let reg = /(?=.*[0-9])(?=.*[a-zA-Z]).{6,30}/;
        if (!reg.test(value)) {
          return Promise.reject("请按格式要求输入");
        }
        return Promise.resolve();
      }
    };

    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("请再次确认新密码!");
      } else if (value !== formState.value.password) {
        return Promise.reject("密码输入不一致");
      } else {
        return Promise.resolve();
      }
    };

    const rules = getInvoiceRules(validatePass, validatePass2);

    const handleSubmit = async () => {
      await useResetPasswordApi({
        mobile: store.state.user.userInfo.mobile,
        ...formState.value
      });
      message.success("修改成功，请重新登录");
      store.dispatch("user/loginOut");
    };

    return {
      codeDisabled,
      codeText,
      formState,
      rules,
      formRef,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      handleGetCode,
      handleSubmit
    };
  }
};
</script>
<style lang="less" scoped>
.page-main-box {
  overflow: hidden;
  background: #fff;
  min-height: 100px;
  position: absolute;
  top: 24px;
  bottom: 24px;
  left: 24px;
  right: 24px;
  min-height: 100px;
  .tip {
    position: absolute;
    right: -24px;
    top: 8px;
  }
  .code-text {
    position: absolute;
    right: 4px;
    line-height: 32px;
  }
  .code {
    color: #1890ff !important;
  }
}
.bg {
  height: 170px;
  width: 200px;
}
</style>
