export const getInvoiceRules = (validatePass, validatePass2) => {
  const rules = {
    phone: [
      {
        required: true,
        message: "请输入手机号",
        trigger: "blur"
      }
    ],
    code: [
      {
        required: true,
        message: "请输入验证码",
        trigger: "blur"
      }
    ],
    password: [
      {
        required: true,
        validator: validatePass,
        trigger: "change"
      }
    ],
    repeat_password: [
      {
        validator: validatePass2,
        trigger: "change"
      }
    ]
  };

  return rules;
};
